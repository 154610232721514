import { CheckSquareOutlined, PhoneOutlined, PrinterOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Row, Table } from 'antd';
import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import logo from './../../logo.svg'
import { useReactToPrint } from "react-to-print";


function OrderDetails({ order }) {
    let [items, setItems] = useState([])
    useEffect(() => {
        axios.get(`/orders/${order.id}`).then(res => {
            setItems(res.data)
        })
    }, [])

    const printRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });
    return (
        <>

            <div ref={printRef}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="logo">
                        <img src={logo} alt="" width={'50px'} />
                        <h3>Bozor</h3>
                    </div>
                    <div>
                        <h3>Shop.uz</h3>
                        <p><b>Tel: </b> +998991112233</p>
                        <p><b>Email: </b> info@gmail.com</p>
                    </div>
                </div>
                <Divider />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h3>№ {order.id} </h3>
                    <div>
                        <p><b>Buyurtma vaqti:</b> {order.created_at}</p>
                        <p><b>To'lov holati:</b> {order.paystatus}</p>
                    </div>
                </div>
                <Divider />
                <p><b>Buyurtmachi:</b> {order.fullname}</p>
                <p><b>Manzil:</b> {order.state}. {order.city}. {order.address}</p>
                <p><b>Email:</b> {order.phone}</p>
                <p><b>Email:</b> {order.email}</p>
                <Divider />
                <p><b>Buyurtma uchun izoh:</b> {order.order_comment}</p>
                <Divider />

                <Table
                    pagination={false}
                    rowKey={'id'}
                    columns={[
                        { title: 'Mahsulot', dataIndex: 'product_name', key: 'product' },
                        { title: 'Narxi', dataIndex: 'product_price', key: 'price' },
                        { title: 'Soni', dataIndex: 'product_quantity', key: 'quantity' },
                        { title: 'Jami', dataIndex: 'total', key: 'total', render: (text, record) => <b>{record.product_price * record.product_quantity} so'm</b> },
                    ]}
                    dataSource={items}
                />
                <Divider />
                <div style={{ textAlign: 'right' }}>
                    <p><b>Buyurtma narxi:</b> {order.subtotal} so'm</p>
                    <p><b>Chegirma:</b> {order.coupon_discount} so'm</p>
                    <p><b>To'lovga:</b> {order.amount} so'm</p>
                </div>

                <Divider />


            </div>
            <Row gutter={15}>
                <Col span={12}>
                    <Button type='text' block style={{
                        backgroundColor: (order.status === 'yangi')
                            ? '#f5f5f5'
                            : (order.status === 'qabul qilindi')
                                ? 'lightblue'
                                : (order.status === 'tayyorlandi')
                                    ? 'lightgreen'
                                    : (order.status === 'yetkazib berildi')
                                        ? 'lightcoral'
                                        : 'lightgray'
                    }} icon={<CheckSquareOutlined />}>{order.status}</Button>
                </Col>
                <Col span={12}>
                    <Button onClick={handlePrint} type='primary' block icon={<PrinterOutlined />}>PRINT</Button>
                </Col>
            </Row>
        </>
    );
}

export default OrderDetails;