import { DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select, Table } from 'antd';
import { Option } from 'antd/es/mentions';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

function Discount(props) {
    let [discounts, setDiscounts] = useState([])
    let [render, setRender] = useState(false)

    const deleteDiscount = (id) => {
        axios.delete(`/discounts/${id}`).then(res => {
            console.log(res)
            setRender(!render)
        }).catch(err => console.log(err))
    }

    let columns = [
        {
            title: 'Kod',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'Muddati',
            dataIndex: 'expiry',
            key: 'expiry',
        },
        {
            title: 'Qiymati',
            dataIndex: 'amount',
            key: 'amount',
        },
        {
            title: 'Turi',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: 'Amallar',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => (
                <div>
                    <Button onClick={()=>{
                        deleteDiscount(record.id)
                    }} type="primary" danger icon={<DeleteOutlined />} />
                </div>
            )
        }
    ]


    useEffect(() => {
        axios.get('/discounts').then(res => {
            setDiscounts(res.data)
        }).catch(err => console.log(err))
    }, [render])

    return (
        <div style={{ backgroundColor: '#fff', padding: '15px', borderRadius: '10px' }}>
            <h3>Chegirmalar</h3>

            <div>
                <Form onFinish={(v) => {
                    console.log(v)
                    axios.post('/discounts', v).then(res => {
                        console.log(res)
                        setRender(!render)
                    }
                    ).catch(err => console.log(err))

                }} labelCol={{ span: 24 }} style={{ backgroundColor: 'aliceblue', padding: '15px', borderRadius: '10px', margin: '10px 0' }}>
                    <Row gutter={10}>
                        <Col span={7}>
                            <Form.Item label="Kod" name={'code'}>
                                <Input type="text" placeholder="Chegirma kodi" />
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item label="Muddati" name={'expiry'}>
                                <Input type="date" placeholder="Chegirma kodi" />
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item label="qiymati" name={'amount'}>
                                <Input type="number" placeholder="Chegirma kodi" />
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item label="Turi" name={'type'}>
                                <Select>
                                    <Option value="foiz">Foiz</Option>
                                    <Option value="sum">Summa</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item label="Test">
                                <Button type="primary" htmlType='submit'>Qo'shish</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
            <div>
                <Table columns={columns} dataSource={discounts} rowKey={'id'} />
            </div>
        </div>
    );
}

export default Discount;