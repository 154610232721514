import { Button, Card, Col, Divider, Modal, Row, Select } from 'antd';
import React, { useState } from 'react';
import OrderDetails from './OrderDetails';
import { PhoneOutlined } from '@ant-design/icons';
import axios from 'axios';

function OrderCard({ order }) {
    let [visible, setVisible] = useState(false)
    let [rend, setRend] = useState(false)
    const onPayStatus = (value, v) => {
        console.log(value, v)
        axios.post('/repayed', { id: value.id, paystatus: v }).then(res => {
            setRend(!rend)
        })
    }
    const onStatus = (value, v) => {
        console.log(value, v)
        axios.post('/restatus', { id: value.id, status: v }).then(res => {
            setRend(!rend)
        })
    }

    return (
        <>
            <Modal title="Buyurtma" open={visible} footer={null} width={1000} onCancel={() => { setVisible(false) }}>
                <OrderDetails order={order} />
            </Modal>
            <Card title={(
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <h3>№ {order.id} - {order.fullname}</h3>
                    <Button variant={'outlined'} icon={<PhoneOutlined />}><a href={'tel:' + order.phone}>{order.phone}</a></Button>
                </div>
            )} bordered={false}
                actions={[
                    <Button type='primary' onClick={() => {
                        setVisible(true)
                    }} block>Batafsil</Button>,
                ]}
            >

                <p><b>Viloyat:</b> {order.state}</p>
                <p><b>Shahar: </b>{order.city}</p>
                <p><b>Manzil: </b>{order.address}</p>
                <Divider />
                <p><b>Email:</b> {order.email}</p>
                <p><b>Buyurtma vaqti:</b> {order.created_at}</p>

                <p>{order.comment}</p>
                <Row gutter={10}>
                    <Col span={12}>
                        <Select onChange={(v) => onPayStatus(order, v)} defaultValue={order.paystatus} style={{ width: '100%' }}>
                            <Select.Option value="To'lanmagan">To'lanmagan</Select.Option>
                            <Select.Option value="To'langan">To'langan</Select.Option>
                        </Select>
                    </Col>
                    <Col span={12}>
                        <Select onChange={(v) => onStatus(order, v)} defaultValue={order.status} style={{ width: '100%' }}>
                            <Select.Option value="Yangi">Yangi</Select.Option>
                            <Select.Option value="Tayyorlanmoqda">Tayyorlanmoqda</Select.Option>
                            <Select.Option value="Yetkazilmoqda">Yetkazilmoqda</Select.Option>
                            <Select.Option value="Yetkazib berildi">Yetkazib berildi</Select.Option>
                            <Select.Option value="Bekor qilindi">Bekor qilindi</Select.Option>
                        </Select>
                    </Col>
                </Row>
            </Card>
        </>
    );
}

export default OrderCard;